<template>
  <b-button
    @click="onClick"
    v-bind="{
      variant,
      size: 'md',
      block
    }"
  >
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'CommonButton',
  props: {
    variant: {
      type: String,
      default() {
        return 'danger'
      },
    },
    block: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
